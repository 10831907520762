import * as React from "react";

// router
import { BrowserRouter } from "react-router-dom";

// components
import RoutesConfig from "./routes";

const MainNavigation: React.FC = () => {
  return (
    <BrowserRouter>
      <RoutesConfig />
    </BrowserRouter>
  );
};

export default MainNavigation;
