import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setLoading } from "../../store/slices/loading";
import {
  ICandidatePoolForm,
  ICheckUserExist,
} from "../../store/types/candidate";
import { setMessage } from "../../store/slices/message";
import { AppDispatch } from "../../store/store";
import { order_apply } from "./apply";

export const create_candidate = async (
  dispatch: AppDispatch,
  data: ICandidatePoolForm,
  order_slug: string = ""
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-candidate", data);

    if (order_slug) order_apply(dispatch, { email: data.email, order_slug });
    dispatch(
      setMessage({
        message: response?.data?.message,
        type: "success",
        title: "We’ll be in touch",
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(
        setMessage({
          message: error?.response?.data?.message,
          type: "error",
          title: "Failed",
        })
      );
    }
  }
  dispatch(setLoading());
};

export const email_exist = async (
  dispatch: AppDispatch,
  data: ICheckUserExist
) => {
  let result;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/check-email", data);

    result = response.data;
  } catch (error) {
    if (isAxiosError(error)) result = error?.response?.data;
  }
  dispatch(setLoading());
  return result;
};
