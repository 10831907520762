import React from "react";

type IRoundedButton = {
  name: string;
  type: "submit" | "button";
  onClick: () => void;
};

const RoundedButton: React.FC<IRoundedButton> = ({ name, type, onClick }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className="bg-primary py-3 w-[112px] md:w-[152px] h-[40px] md:h-[52px] flex justify-center items-center rounded-[36px] text-[13px] md:text-[16px] b_shadow text-[#fff]"
    >
      {name}
    </button>
  );
};

export default RoundedButton;
