import React from 'react'

// components
import { Modal, Spin } from 'antd'

// constants
import thanks from '../../assets/images/alert/thanks-you.svg'
import error from '../../assets/images/alert/error.svg'

// state
import { clearMessage } from '../../store/slices/message'
import { useAppDispatch, useAppSelector } from '../../store'

type ISweetAlert = {
    visible: boolean
    button_name?: string
    loading?: boolean
    onSubmit?: () => void
}

const SweetAlert: React.FC<ISweetAlert> = ({ visible, button_name, loading, onSubmit }) => {
    const dispatch = useAppDispatch()
    const message = useAppSelector(({ message }) => message.message)

    return (
        <Modal open={visible} centered footer={null} closable={false}>
            <div className='font-abel w-full flex flex-col justify-center items-center py-4 px-2 rounded-[32px]'>
                <p className='mb-3 md:text-[32px] text-[24px] font-abel text-[#4E4E4E]'>{message?.title}</p>
                <div className='md:h-[120px] md:w-[120px] my-3'>
                    <img src={message?.type === "success" ? thanks : error} alt="alert" className='w-full h-full' />
                </div>
                {message?.message && <p className='md:text-[20px] text-[16px] text-center font-abel text-[#4E4E4E] my-3' dangerouslySetInnerHTML={{ __html: message.message }} />}
                <button
                    className="button font-abel bg-[#0B60D6] rounded-[13px] text-[white] hover:bg-[#CDD8FE] hover:text-[#0B60D6] cursor-pointer text-[16px] px-8 py-3 mt-5"
                    onClick={() => {
                        dispatch(clearMessage());
                        onSubmit?.();
                    }}
                >
                    {loading && <Spin />} {button_name || 'Go back home'}
                </button>
            </div>
        </Modal>
    );
};     

export default SweetAlert