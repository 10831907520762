import React from "react";
// router
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

// auth and pages
// import { ComingSoon } from '../screens';

// components
// import SuspenceLoader from "../../components/suspence";
import Hire from "../../screens/hire";
import Candidate from "../../screens/candidate";
import Contact from "../../screens/contact";
import Marketplace from "../../screens/marketplace";
import JD from "../../screens/marketplace/jd";
import { AnimatePresence } from "framer-motion";
import HomeScreen from "../../screens/dashboard";

// lazy loading
// const Home = React.lazy(() => import("../../screens/dashboard"));

const RoutesConfig = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes key={location.pathname} location={location}>
        {/* Redirect */}
        <Route path="/jobs" element={<Navigate to="/marketplace" replace />} />
        <Route
          path="/offers"
          element={<Navigate to="/marketplace" replace />}
        />

        <Route path="/" element={<HomeScreen />} />
        <Route path="/hire" element={<Hire />} />
        <Route path="/candidate" element={<Candidate />} />
        <Route path="/apply/candidate/:slug" element={<Candidate />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/marketplace/:slug" element={<JD />} />
      </Routes>
    </AnimatePresence>
  );
};

export default RoutesConfig;
