import logo from '../assets/images/25th-blue-logo.svg'
import logoWhite from '../assets/images/25th-blue-logo-white.svg'
import check from '../assets/images/icons/check.svg'
import down from '../assets/images/icons/down.svg'
import upload from '../assets/images/icons/upload.svg'
import about from '../assets/images/about-25thblue.jpg'
import help from '../assets/images/help.jpg'

const db = {
    logo: logo,
    logoWhite: logoWhite,
    check: check,
    down: down,
    upload: upload,
    about: about,
    help: help,

    difference: [
        {
            id: 1, icon: check, title: 'Commitment to Quality', subtitle: "Quality is at the heart of everything we do. We're committed to upholding the highest standards of professionalism, integrity, and excellence in all our interactions."
        },
        { id: 2, icon: check, title: 'Seamless Application Process', subtitle: 'With our user-friendly interface, you can apply for jobs with just a few clicks, making the process quick and hassle-free.' },
        { id: 3, icon: check, title: 'Verified Opportunities', subtitle: 'Every job listing on our platform undergoes a thorough verification process, ensuring that you connect with reputable employers committed to your success.' },
        { id: 4, icon: check, title: 'Reliability and Efficiency', subtitle: 'Our streamlined processes and dedicated team ensure that we deliver results quickly and efficiently, saving our clients time and resources.' },
        { id: 5, icon: check, title: 'Background Check', subtitle: 'We go the extra mile to ensure peace of mind for both employers and workers. Our rigorous background checks guarantee the reliability and trustworthiness of every candidate we recommend.' },
        {
            id: 6, icon: check, title: 'Personalized Approach', subtitle: "We take the time to understand our clients' specific requirements and tailor our services to meet their needs, ensuring a personalized and effective hiring experience."
        },
    ],

    howItWorks: [
        { id: 1, number: '1', title: "Submit Your Job Requirements", content: "Tell us about your staffing needs and job requirements through our online portal or by contacting our team directly." },
        { id: 2, number: '2', title: "Candidate Matching", content: "Our team will carefully match your job requirements with the skills and experience of our registered blue-collar professionals." },
        { id: 3, number: '3', title: "Interview and Selection", content: "Select candidates for interviews based on our recommendations, or let us handle the screening process on your behalf." },
        { id: 4, number: '4', title: "Onboarding Support", content: "Once you've chosen your ideal candidates, we provide assistance with the onboarding process to ensure a smooth transition into your workforce." },
    ],
    howItWorksCandidate: [
        { id: 1, number: '1', title: "Upload Your Resume", content: "Start by uploading your resume through our user-friendly platform. Don't worry – it's quick and easy!" },
        { id: 2, number: '2', title: "Profile Review", content: "Our team of experienced recruiters will carefully review your resume, taking into account your skills, experience, and career aspirations." },
        { id: 3, number: '3', title: "Matching Process", content: "Using our advanced matching algorithms and industry expertise, we'll identify job opportunities that are the perfect fit for you." },
        { id: 4, number: '4', title: "Get Hired", content: "Once we've found the right job match, we'll guide you through the hiring process and provide support every step of the way." },
    ],
    prospective: [
        { id: 1, name: 'Client' },
        { id: 2, name: 'Candidate' },
    ],
    jobsAvailable: [
        { id: 1, jobTitle: 'Driver', location: 'Victoria Island, Lagos', link: '/marketplace/jd', date: '3 weeks' },
        { id: 2, jobTitle: 'Chef', location: 'Ikeja, GRA, Lagos', link: '/marketplace/jd', date: '2 weeks' },
        { id: 3, jobTitle: 'Nanny', location: 'Lekki Phase 1, Lagos', link: '/marketplace/jd', date: '3 weeks' },
        { id: 4, jobTitle: 'Driver', location: 'Victoria Island, Lagos', link: '/marketplace/jd', date: '3 weeks' },
        { id: 5, jobTitle: 'Driver', location: 'Victoria Island, Lagos', link: '/marketplace/jd', date: '3 weeks' },
    ]
}

export default db;