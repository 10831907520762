import React from "react";

// constants and router
import db from "../../constants/database";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";

// components
import Footer from "../../themes/footer";
import Navbar from "../../themes/header";
import AnimatedPage from "../../components/animation/pages/AnimatedPage";
import AnimatedButton from "../../components/animation/forms/AnimatedButton";

const LandinPage: React.FC = () => {
  const navigate = useNavigate();
  const container_ref = React.useRef(null);
  const in_view = useInView(container_ref, { once: true });

  const main_controls = useAnimation();

  React.useEffect(() => {
    if (in_view) main_controls.start("visible");
  }, [in_view, main_controls]);

  return (
    <AnimatedPage>
      <div className="font-abel">
        <Navbar />
        <div className="md:pr-[70px] md:right-2 flex justify-end mb-7">
          <Link
            to="https://25thandstaffing.com"
            className="text-[#0B60D6] md:text-[17px] text-[14px] font-bold rounded-default border-[1px] border-[#0B60D6] hover:bg-[#CDD8FE] hover:text-[#243677] py-2 px-4 mr-3 md:mr-5 mt-2"
          >
            25th and Staffing
          </Link>
        </div>
        <div
          className="max-w-[754px] mx-auto text-center md:mt-12 mt-8 px-3"
          ref={container_ref}
        >
          <h1 className="md:text-[64px] text-[35px] md:leading-[70px] leading-[45px]">
            The trusted way to hire blue collar talents in Nigeria
          </h1>
          <p className="md:text-[24px] text-[17px] max-w-[583px] mx-auto md:py-10 py-7 text-[#4E4E4E]">
            Explore Thousands of Blue Collar Job Opportunities Across Various
            Industries and Locations. Start Your Journey to Success Today
          </p>
        </div>
        <div className="bg_people pt-4 md:pt-7"></div>
        <div
          className="flex justify-center items-center px-3"
          ref={container_ref}
        >
          <AnimatedButton
            background="rgb(255, 255, 255)"
            color="rgb(11, 96, 214)"
            title="Hire a Domestic Staff"
            onClick={() => navigate("/hire")}
            type="button"
          />
          <div className="ml-[10px] md:ml-[25px]"></div>
          <Link
            to="/candidate"
            className="border-[#0B60D6] border-[2px] py-3 w-[164px] md:w-[174px] h-[52px] flex justify-center items-center rounded-default text-[13px] md:text-[16px] b_shadow text-[#4E4E4E] hover:scale-105 hover:bg-[#0B60D6] hover:text-[#ffffff]"
          >
            Upload your resume
          </Link>
        </div>
        <div className="flex justify-end items-center container py-6 px-3"></div>
        <div className="bg-[#D2E8FF] mt-[30px] " ref={container_ref}>
          <div className="max-w-[1161px] mx-auto md:py-[100px] py-[50px] px-3">
            <h2 className="md:text-[40px] text-[24px] text-center pb-[25px] md:pb-[50px]">
              Our Services
            </h2>
            <div className="flex justify-between flex-wrap w-full">
              <motion.div
                whileTap={{ scale: 1.02 }}
                whileHover={{ scale: 1.01 }}
                transition={{ duration: 0.3 }}
                className="bg-primary md:w-[60%] w-full md:h-[490px] h-[380px] mb-5"
              >
                <div className="bg_talent">
                  <div className="w-full h-full text-center text-[white] max-w-[429px] m-auto pt-20 px-5">
                    <h4 className="md:text-[32px] text-[20px]">
                      Talent Acquisition
                    </h4>
                    <p className="md:text-xl text-[16px] pt-11">
                      We specialize in sourcing and vetting top-tier blue-collar
                      talent across various industries, ensuring that our
                      clients have access to the best candidates for their
                      needs.
                    </p>
                  </div>
                </div>
              </motion.div>
              <motion.div
                whileTap={{ scale: 1.02 }}
                whileHover={{ scale: 1.01 }}
                transition={{ duration: 0.3 }}
                className="bg-[#319759] md:w-[38%] w-full md:h-[490px] h-[380px] mb-5"
              >
                <div className="bg_job">
                  <div className="w-full h-full text-center text-[white] max-w-[384px] m-auto pt-20 px-5">
                    <h4 className="md:text-[32px] text-[20px]">
                      Job Placement
                    </h4>
                    <p className="md:text-xl text-[16px] pt-11">
                      From cleaning and driving to cooking and gardening, we
                      connect skilled workers with reputable employers,
                      facilitating seamless job placements.
                    </p>
                  </div>
                </div>
              </motion.div>
              <motion.div
                whileTap={{ scale: 1.02 }}
                whileHover={{ scale: 1.01 }}
                transition={{ duration: 0.3 }}
                className="bg-[#000000] md:w-[38%] w-full md:h-[490px] h-[380px] mb-5"
              >
                <div className="bg_ondemand">
                  <div className="w-full h-full text-center text-[white] max-w-[384px] m-auto pt-20 px-5">
                    <h4 className="md:text-[32px] text-[20px]">
                      On-Demand Staffing
                    </h4>
                    <p className="md:text-xl text-[16px] pt-11">
                      Need temporary or project-based staffing solutions? Our
                      on-demand staffing services offer flexibility and agility
                      to meet your business's evolving needs.
                    </p>
                  </div>
                </div>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.01 }}
                transition={{ duration: 0.3 }}
                whileTap={{ scale: 1.02 }}
                className="bg-[#243677] md:w-[60%] w-full md:h-[490px] h-[380px] mb-5"
              >
                <div className="bg_training">
                  <div className="w-full h-full text-center text-[white] max-w-[565px] m-auto pt-20 px-5">
                    <h4 className="md:text-[32px] text-[20px]">
                      Background Check
                    </h4>
                    <p className="md:text-xl text-[16px] pt-11">
                      We're dedicated to ensuring the integrity and reliability
                      of our candidates. With our comprehensive background check
                      process, we meticulously verify candidate credentials by
                      running medical checks, getting police reports and
                      references from previous places of employment. We also
                      ensure that each candidate is properly verified by
                      providing a valid identity card and list of guarantors.
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        <div className="bg-primary" ref={container_ref}>
          <div className="max-w-[1044px] mx-auto md:py-[100px] py-[50px] px-3 text-[#ffffff]">
            <h2 className="md:text-[40px] text-[24px] text-center pb-[25px] md:pb-[50px]">
              What makes us different?
            </h2>

            <div className="flex justify-between flex-wrap">
              {db.difference?.map((item) => (
                <motion.div
                  key={item.id}
                  whileHover={{ scale: 1.01 }}
                  className="lg:max-w-[308px] md:max-w-[48%] rounded-[4px] border-[#FFFFFF] border py-[30px] px-[20px] md:mb-8 mb-5 different"
                >
                  <img src={item.icon} alt="blue" />

                  <h3 className="text-[18px] md:text-xl py-[10px]">
                    {item.title}
                  </h3>
                  <p className="text-[16px]">{item.subtitle}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="w-full relative md:h-[925px] h-[550px]"
          ref={container_ref}
        >
          <div className="w-full flex flex-wrap absolute z-0">
            <div className="md:bg-about bg-cover bg-no-repeat w-[40%] md:h-[925px] h-[550px]"></div>
            <div className="bg-[white] w-[60%]"></div>
          </div>
          <div className="relative z-10 md:pt-28 pt-[50px] px-3">
            <h4 className="md:text-[40px] text-[24px] text-[#4E4E4E] text-center pb-[25px] md:pb-[50px]">
              About Us
            </h4>
            <motion.div
              className="flex w-full items-center justify-center"
              animate={main_controls}
              initial="hidden"
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              transition={{ delay: 0.5 }}
            >
              <div className="about_img md:max-w-[620px] md:mr-10 md:block hidden">
                <img src={db.about} alt="about 25th blue" />
              </div>
              <div className="max-w-[504px]">
                <p className="md:text-xl text-[16px] pb-10">
                  At 25th Blue, we believe that every individual deserves the
                  chance to thrive in a fulfilling career. With a focus on
                  blue-collar industries, we're dedicated to bridging the gap
                  between talented workers and employers seeking their skills.
                  Our mission is simple: to empower both job seekers and
                  businesses by providing efficient, reliable, and tailored
                  hiring solutions.
                </p>

                <AnimatedButton
                  background="rgb(255, 255, 255)"
                  color="rgb(11, 96, 214)"
                  title="Hire a Domestic Staff"
                  onClick={() => navigate("/hire")}
                  type="button"
                />
              </div>
            </motion.div>
          </div>
        </div>

        <Footer />
      </div>
    </AnimatedPage>
  );
};

export default LandinPage;
