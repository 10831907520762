import React from "react";

// components
import { Modal } from "antd";
import TextInput from "../../../components/forms/TextInput";

// types
import { } from "../../../services/api/order";
import { email_exist } from "../../../services/api/candidate";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";
import { order_apply } from "../../../services/api/apply";

type ICheckEmail = {
  open: boolean;
  order_slug: string;
  onCancel: () => void;
};

const CheckEmail: React.FC<ICheckEmail> = ({ open, order_slug, onCancel }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(({ loading }) => loading.loading);
  const [email, emailSet] = React.useState<string>("");

  return (
    <Modal
      title="Enter Email"
      open={open}
      confirmLoading={loading}
      onOk={async () => {
        const response = await email_exist(dispatch, { email });
        if (response && email.length)
          order_apply(dispatch, { email, order_slug });
        else navigate(`/apply/candidate/${order_slug}`);
      }}
      centered={true}
      onCancel={onCancel}
      okButtonProps={{
        style: { color: "#0B60D6", border: "1px solid #0B60D6" },
      }}
      cancelButtonProps={{
        style: { color: "#D81B1B", border: "1px solid #D81B1B" },
      }}
      okText="Submit"
    >
      <TextInput
        name="email"
        placeholder=""
        value={email}
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
          emailSet(target.value)
        }
      />
    </Modal>
  );
};

export default CheckEmail;
