import React from "react";
import Navbar from "../../themes/header";
import { Link } from "react-router-dom";
import Footer from "../../themes/footer";
import db from "../../constants/database";
import { useAppDispatch, useAppSelector } from "../../store";
import { get_orders } from "../../services/api/order";
import Role from "./misc/Role";
import { get_week_past } from "../../services/converter/date";
import AnimatedPage from "../../components/animation/pages/AnimatedPage";
import EmptyOrder from "./misc/EmptyOrder";
import OrdersLoader from "./misc/OrdersLoader";

const Marketplace: React.FC = () => {
  const dispatch = useAppDispatch();
  const [click, clickSet] = React.useState<string[]>([]);
  const [q, qSet] = React.useState<string>("");
  const orders = useAppSelector(({ order }) => order.orders);
  const loading = useAppSelector(({ order }) => order.loading);

  const onLoad = React.useCallback(() => {
    get_orders(dispatch, q, click);
  }, [click, dispatch, q]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <AnimatedPage>
      <div className="font-abel">
        <Navbar />
        <div className="bg-[#0B60D6] w-full py-9 px-5">
          <div className="bg-marketplace bg-cover bg-no-repeat h-[594px] flex justify-center items-center">
            <div className="max-w-[737px] mx-auto text-center">
              <h1 className="text-[#CDD8FE] md:text-4xl text-2xl">
                Job marketplace
              </h1>
              <p className="text-[white] md:text-[20px] text-lg max-w-[680px] mx-auto pt-8">
                Whether you're seeking a career change or looking to hire
                skilled workers, our platform offers a seamless experience for
                connecting employers with talented blue-collar workers. Browse
                job listings, post vacancies, and find your perfect match today.
              </p>
              <div className="flex justify-center items-center py-11">
                <Link
                  to="/candidate"
                  className="bg-[white] py-3 w-[144px] md:w-[184px] h-[52px] flex justify-center items-center rounded-default text-[13px] md:text-[16px] b_shadow text-[#1E1E1E]"
                >
                  Join our Talent Network
                </Link>
                <div className="ml-[10px] md:ml-[25px]"></div>
                <Link
                  to="/hire"
                  className="border-[#ffffff] border-[2px] py-3 w-[144px] md:w-[184px] h-[52px] flex justify-center items-center rounded-default text-[13px] md:text-[16px] b_shadow text-[#ffffff]"
                >
                  I am an employer
                </Link>
              </div>
              <div className="bg-[white] py-3 px-6 w-[100%] h-[52px] flex justify-center items-center rounded-default b_shadow text-[#1E1E1E]">
                <input
                  type="text"
                  placeholder="Job Search"
                  value={q}
                  name="q"
                  onChange={(e) => {
                    const { value } = e.target;
                    qSet(value);
                  }}
                  className="w-[100%] text-[13px] md:text-[16px] bg-[white]"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap md:flex-wrap lg:flex-nowrap">
          <div className="bg-[#F7F8FE] w-[393px] px-6 py-10">
            <div className="max-w-[300px] mx-auto">
              <Link
                to="/marketplace"
                className="bg-primary py-3  h-[52px] flex justify-center items-center rounded-[36px] text-[13px] md:text-[16px] b_shadow text-[#fff]"
              >
                All Available Jobs
              </Link>
              <Role
                selected={click}
                onChange={(res) => {
                  if (click.includes(res))
                    clickSet(click.filter((item) => item !== res));
                  else clickSet([...click, res]);
                }}
              />

              <div className="bg-[white] py-3 px-6 w-[100%] h-[384px] flex justify-center items-center rounded-[12px] text-[#1E1E1E]">
                <div className="text-center">
                  <p className="text-[32px] font-semibold text-[#100F0F]">
                    Need Help?
                  </p>
                  <img src={db.help} alt="help" />
                  <Link
                    to="/contact"
                    className="bg-primary py-3 w-[142px] h-[52px] flex justify-center items-center rounded-[36px] text-[13px] md:text-[16px] b_shadow text-[#fff] mx-auto"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-[#ffffff] w-[100%] md:w-[70%] lg:w-[70%] px-5 py-10">
            {loading ? (
              <OrdersLoader />
            ) : !loading && orders.length < 1 ? (
              <EmptyOrder />
            ) : (
              orders?.map((item) => (
                <div
                  key={item.slug}
                  className="bg-[#E1EEFF] h-[143px] md:h-[193px] px-4 max-w-[905px] mx-auto mb-5"
                >
                  <div className="flex justify-between items-center max-w-[790px] mx-auto h-[100%]">
                    <div>
                      <p className="text-[20px] md:text-[26px] text-[#100F0F] capitalize">
                        {item.role?.name}
                      </p>
                      <p className="text-[13px] md:text-[16px] text-[#4E4E4E] pt-2 capitalize">
                        {`${item?.state ? item?.state?.name + "," : ""} ${
                          item?.state ? item?.state?.country?.name : ""
                        }`}
                      </p>
                    </div>
                    <div className="text-center">
                      <Link
                        to={`/marketplace/${item.slug}`}
                        className="bg-primary py-3 w-[112px] md:w-[142px] h-[40px] md:h-[52px] flex justify-center items-center rounded-[36px] text-[13px] md:text-[16px] b_shadow text-[#fff] mx-auto"
                      >
                        View JD
                      </Link>
                      <p className="text-[13px] md:text-[16px] text-[#243677] pt-4">
                        {`Posted ${
                          item?.created_at
                            ? get_week_past(item?.created_at)
                            : "Recently Posted"
                        }`}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <Footer />
      </div>
    </AnimatedPage>
  );
};

export default Marketplace;
