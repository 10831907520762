import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setLoading } from "../../store/slices/loading";
import { setMessage } from "../../store/slices/message";
import { AppDispatch } from "../../store/store";

type IData = {
  email: string;
  order_slug: string;
};

type IApply = (dispatch: AppDispatch, data: IData) => void;
export const order_apply: IApply = async (dispatch, data) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/apply", data);

    dispatch(
      setMessage({
        message: response?.data?.message,
        type: "success",
        title: "We’ll be in touch",
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(
        setMessage({
          message: error?.response?.data?.message,
          type: "error",
          title: "Failed",
        })
      );
    }
  }
  dispatch(setLoading());
};
