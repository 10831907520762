import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setRoles } from "../../store/slices/role";
import { AppDispatch } from "../../store/store";

export const get_roles = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get("/get-roles");

    dispatch(setRoles(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      console.log(error?.response);
      console.log(error?.response?.data);
    }
  }
};

export const get_available_total = async (id: string) => {
  try {
    const response = await axiosInstance.get(`/get-total-available-role/${id}`);

    return response.data;
  } catch (error) {
    return 0;
  }
};
