import React from "react";

// components
import { Checkbox } from "antd";

// types
import { get_roles } from "../../../services/api/role";
import { useAppDispatch, useAppSelector } from "../../../store";
import TotalRoleAvailable from "./TotalRoleAvailable";

type IRole = {
  selected: string[];
  onChange: (data: string) => void;
};

const Role: React.FC<IRole> = ({ selected, onChange }) => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(({ role }) => role.roles);

  const onLoad = React.useCallback(() => {
    get_roles(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="bg-[white] py-8 px-6 w-[100%] h-[336px] overflow-scroll rounded-[12px] text-[#1E1E1E] my-10 text-[16px] md:text-[18px]">
      {roles.map((item) => (
        <div className="flex justify-between w-[100%] capitalize mb-5">
          <Checkbox
            key={item.id}
            onChange={(e) => onChange(item.id?.toString())}
            className="text-[16px] md:text-[18px] font-abel"
            checked={selected.includes(item.id?.toString())}
          >
            {item.name}
          </Checkbox>
          <TotalRoleAvailable id={item.id?.toString()} />
        </div>
      ))}
    </div>
  );
};

export default Role;
