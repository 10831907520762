import React from "react";
import Navbar from "../../themes/header";
// import NavbarDark from "../../themes/header-dark";
import Footer from "../../themes/footer";
import SelectInput from "../../components/forms/SelectInput";
import { useAppDispatch, useAppSelector } from "../../store";
import TextInput from "../../components/forms/TextInput";
import TextInputBox from "../../components/forms/TextInputBox";
import SubmitButton from "../../components/forms/SubmitButton";
import { get_countries, get_states } from "../../services/api/region";
import { get_prospectives } from "../../services/api/prospective";
import { IContactUs, IContactUsError } from "../../store/types/contact";
import { store_contact } from "../../services/api/contact";
import SweetAlert from "../../components/sweetalert";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "../../components/animation/pages/AnimatedPage";

const Contact: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const countries = useAppSelector(({ region }) => region.countries);
  const states = useAppSelector(({ region }) => region.states);
  const loading = useAppSelector(({ loading }) => loading.loading);
  const message = useAppSelector(({ message }) => message.message);
  const prospectives = useAppSelector(
    ({ prospective }) => prospective.prospectives
  );

  const [data, dataSet] = React.useState<IContactUs>({
    country_id: "",
    email: "",
    first_name: "",
    last_name: "",
    message: "",
    phone: "",
    prospective_id: "",
    state_id: "",
  });

  const [error, errorSet] = React.useState<IContactUsError>({
    country_id: "",
    email: "",
    first_name: "",
    last_name: "",
    message: "",
    phone: "",
    prospective_id: "",
    state_id: "",
  });

  const onLoad = React.useCallback(() => {
    get_prospectives(dispatch);
    get_countries(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onChange = (name: string, value: string) => {
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  const onSubmit = () => {
    let validate: boolean = false;
    if (!data.email) {
      validate = true;
      errorSet((prev) => ({ ...prev, email: "warning" }));
    }
    if (!data.first_name) {
      validate = true;
      errorSet((prev) => ({ ...prev, first_name: "warning" }));
    }
    if (!data.last_name) {
      validate = true;
      errorSet((prev) => ({ ...prev, last_name: "warning" }));
    }
    if (!data.message) {
      validate = true;
      errorSet((prev) => ({ ...prev, message: "warning" }));
    }
    if (!data.phone) {
      validate = true;
      errorSet((prev) => ({ ...prev, phone: "warning" }));
    }
    if (!data.prospective_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, prospective_id: "warning" }));
    }
    if (!data.state_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, state_id: "warning" }));
    }
    if (validate) return;
    store_contact(dispatch, data);
  };

  return (
    <AnimatedPage>
      <div className="font-abel">
        <Navbar />
        <div className="bg-[black] w-full pb-36">
          {/* <NavbarDark /> */}
          <div className="max-w-[941px] mx-auto text-center md:pt-12 pt-8 px-3">
            <h1 className="text-[#CDD8FE] md:text-4xl text-2xl">
              How can we help you?
            </h1>
            <p className="text-[white] md:text-2xl text-lg max-w-[941px] mx-auto pt-10">
              Have questions about our blue-collar hiring solutions or need
              assistance with your recruitment needs? Get in touch with our
              dedicated team today. Whether you're a job seeker looking for
              opportunities or an employer seeking skilled talent, we're here to
              help. Reach out to us via phone, email, or fill out the form
              below, and we'll get back to you promptly.
            </p>
            <p className="text-[#CDD8FE] md:text-[20px] text-lg max-w-[576px] mx-auto py-10">
              Let us know who you are and what you are looking for?
            </p>
          </div>
          <div className="max-w-[1060px] md:mx-auto rounded-[36px] bg-[white] md;py-[75px] py-10 mx-3 mt-5">
            <div className="max-w-[935px] mx-auto md:py-8 py-4 px-3">
              <div className="flex justify-between">
                <div className="w-[47%]">
                  <p className="text-[13px] md:text-[16px]">
                    I am a prospective . . .
                  </p>
                  <SelectInput
                    label=""
                    name="prospective_id"
                    required={true}
                    status={error.prospective_id}
                    items={prospectives}
                    onResponse={(name, value) => onChange(name, value)}
                  />
                </div>

                <div className="w-[47%]">
                  <p className="text-[13px] md:text-[16px]">First Name</p>
                  <div className="w-[100%]">
                    <TextInput
                      placeholder="First Name"
                      name="first_name"
                      value={data.first_name}
                      status={error.first_name}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        onChange(name, value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-[47%]">
                <p className="text-[13px] md:text-[16px]">Last Name</p>
                <TextInput
                  placeholder="Last Name"
                  name="last_name"
                  value={data.last_name}
                  status={error.last_name}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    onChange(name, value);
                  }}
                />
              </div>

              <div className="flex justify-between">
                <div className="w-[47%]">
                  <p className="text-[13px] md:text-[16px]">Country</p>
                  <div className="w-[100%]">
                    <SelectInput
                      label=""
                      name="country_id"
                      required={true}
                      status={error.country_id}
                      items={countries}
                      onResponse={(name, value) => {
                        onChange(name, value);
                        get_states(dispatch, value);
                      }}
                    />
                  </div>
                </div>

                <div className="w-[47%]">
                  <p className="text-[13px] md:text-[16px]">State</p>
                  <div className="w-[100%]">
                    <SelectInput
                      label=""
                      name="state_id"
                      required={true}
                      status={error.state_id}
                      items={states}
                      onResponse={(name, value) => {
                        onChange(name, value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="w-[47%]">
                  <p className="text-[13px] md:text-[16px]">Email Address</p>
                  <TextInput
                    placeholder="Email"
                    name="email"
                    value={data.email}
                    status={error.email}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      onChange(name, value);
                    }}
                  />
                </div>

                <div className="w-[47%]">
                  <p className="text-[13px] md:text-[16px]">Phone Number</p>
                  <div className="w-[100%]">
                    <TextInput
                      placeholder="Phone Number"
                      name="phone"
                      value={data.phone}
                      status={error?.phone}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        onChange(name, value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full">
                <p className="text-[13px] md:text-[16px]">Message</p>
                <TextInputBox
                  label=""
                  name="message"
                  placeholder="Enter Message"
                  status={error.message}
                  value={data.message}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    onChange(name, value);
                  }}
                />
              </div>
              <div className="flex justify-center mt-8">
                <div className="w-[150px] ml-3">
                  <SubmitButton
                    title="Submit"
                    onSubmitHandler={onSubmit}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SweetAlert
          visible={message ? true : false}
          onSubmit={() => navigate("/")}
        />
        <Footer />
      </div>
    </AnimatedPage>
  );
};

export default Contact;
