import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setProspectives } from "../../store/slices/propsective";
import { AppDispatch } from "../../store/store";

export const get_prospectives = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get("/get-prospectives");

    dispatch(setProspectives(response.data));
  } catch (error) {
    if (isAxiosError(error))
      dispatch(setProspectives([{ id: "1", name: "client" }]));
  }
};
