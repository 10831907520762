import SelectFile from "../../../components/forms/SelectFile";
import SelectInput from "../../../components/forms/SelectInput";
import SubmitButton from "../../../components/forms/SubmitButton";
import TextInput from "../../../components/forms/TextInput";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  ICandidatePoolForm,
  ICandidatePoolFormError,
} from "../../../store/types/candidate";
import { create_candidate } from "../../../services/api/candidate";
import { useParams } from "react-router-dom";

type ICandidateForm = {
  data: ICandidatePoolForm;
  error: ICandidatePoolFormError;
  onChange: (name: string, value: string) => void;
  onSubmit: () => boolean;
  onBack: () => void;
};

const CandidateForm2: React.FC<ICandidateForm> = ({
  data,
  error,
  onChange,
  onSubmit,
  onBack,
}) => {
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  const roles = useAppSelector(({ role }) => role.roles);
  const marital_statuses = useAppSelector(
    ({ marital_status }) => marital_status.marital_statuses
  );
  console.log(slug);
  return (
    <>
      <div className="w-full">
        <TextInput
          value={data.n_o_k_address}
          placeholder="Contact Address (Next of Kin)"
          name="n_o_k_address"
          status={error.n_o_k_address}
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>
      <div className="w-full">
        <TextInput
          value={data.n_o_k_phone_number}
          placeholder="Phone Number (Next of Kin)"
          name="n_o_k_phone_number"
          status={error.n_o_k_phone_number}
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>
      <div className="w-full">
        <SelectInput
          label="Marital Status"
          name="marital_status_id"
          status={error.marital_status_id}
          items={marital_statuses}
          onResponse={(name, value) => onChange(name, value)}
        />
      </div>
      <div className="w-full">
        <TextInput
          value={data.number_of_dependant}
          placeholder="Number of Dependents"
          name="number_of_dependant"
          status={error.number_of_dependant}
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>

      <div className="w-full">
        <SelectInput
          label="Role"
          name="role_id"
          status={error.role_id}
          items={roles}
          onResponse={(name, value) => onChange(name, value)}
        />
      </div>

      <div className="w-full">
        <TextInput
          value={data.experience_and_skills}
          placeholder="Experience and Skills"
          name="experience_and_skills"
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>

      <div className="w-full">
        <TextInput
          value={data.years_of_experience}
          placeholder="How many years have you worked in the role applied for?"
          name="years_of_experience"
          status={error.years_of_experience}
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>

      <SelectFile
        name="resume"
        onDone={(name, value) => onChange(name, value)}
      />

      <div className="flex justify-center mt-8 w-100">
        <div className="w-[150px]">
          <SubmitButton title="Back" onSubmitHandler={() => onBack()} />
        </div>

        <div className="w-[150px] ml-3">
          <SubmitButton
            title="Submit"
            onSubmitHandler={() => {
              if (onSubmit()) create_candidate(dispatch, data, slug);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CandidateForm2;
