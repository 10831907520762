import CryptoJS from "crypto-js";
import env from "../config/env";

export const encrypt = (data: string) => {
  if (env.crypt) return CryptoJS.AES.encrypt(data, env.crypt).toString();
};

export const decrypt = (data: string) => {
  if (env.crypt) {
    const bytes = CryptoJS.AES.decrypt(data, env.crypt);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
};
