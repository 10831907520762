import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setLoading } from "../../store/slices/loading";
import { setMessage } from "../../store/slices/message";
import { IContactUs } from "../../store/types/contact";
import { AppDispatch } from "../../store/store";

export const store_contact = async (
  dispatch: AppDispatch,
  data: IContactUs
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/contact-us", data);

    dispatch(
      setMessage({
        message: response?.data?.message,
        type: "success",
        title: "We’ll be in touch",
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(
        setMessage({
          message: error?.response?.data?.message,
          type: "error",
          title: "Failed",
        })
      );
    }
  }
  dispatch(setLoading());
};
