import React from "react";

// components
import { ErrorWarning, IDataType } from "../../store/types/misc";

type TextInputProps = {
  name: string;
  label?: string;
  container?: string;
  items: IDataType[];
  onResponse: (name: string, value: string) => void;
  error?: "warning" | "";
  prefix?: React.ReactNode;
  className?: string;
  status?: ErrorWarning;
  required?: boolean;
  value?: string;
};

const SelectInput: React.FC<TextInputProps> = ({
  name,
  label,
  items,
  status,
  container,
  onResponse,
}) => {
  const borderColor = status ? "border-[red]" : "border-[#D9D9D9]";

  return (
    <div className={`${container} mb-7 font-abel outline-none`}>
      <select
        id={name}
        name={name}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const { name, value } = e.target;
          onResponse(name, value);
        }}
        className={`w-full h-[60px] font-abel bg-gray-50 py-3 px-5 border border-[#D9D9D9] outline-none text-[#303030] text-[16px] rounded-[4.5px] hover:border-[#243677] focus-within:border-[#243677] block p-2.5 capitalize ${borderColor}`}
      >
        <option defaultValue="">{label || "Please Select..."}</option>
        {items?.map((item) => (
          <option value={item?.id} key={item?.id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
