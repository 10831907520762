import React from "react";

// components
import { Skeleton } from "antd";

const loops: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

const OrdersLoader = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center">
      {loops.map((item) => (
        <Skeleton.Button
          key={item}
          active={true}
          block
          style={{ height: "150px", margin: "10px 0" }}
        />
      ))}
    </div>
  );
};

export default OrdersLoader;
