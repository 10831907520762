import React from "react";

// components
import Navbar from "../../../themes/header";
import Footer from "../../../themes/footer";
import CheckEmail from "../misc/CheckEmail";
import RoundedButton from "../../../components/forms/RoundedButton";

// types
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_order } from "../../../services/api/order";
import {} from "../../../services/api/candidate";
import SweetAlert from "../../../components/sweetalert";
import AnimatedPage from "../../../components/animation/pages/AnimatedPage";

const JD: React.FC = () => {
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const message = useAppSelector(({ message }) => message.message);
  const order = useAppSelector(({ order }) => order.order);
  const [show, showSet] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    if (slug) get_order(dispatch, slug);
  }, [dispatch, slug]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <AnimatedPage>
      <div className="font-abel">
        <Navbar />
        <div className="bg-[#F7F8FE] pb-28">
          <div className="bg-[#E1EEFF] w-full h-[293px]">
            <div className="flex justify-between items-center container mx-auto h-[100%] px-3">
              <div>
                <p className="text-[24px] md:text-[40px] text-[#100F0F] font-semibold capitalize">
                  {order?.role?.name}
                </p>
                <p className="text-[16px] md:text-[19px] text-[#4E4E4E] capitalize">
                  {`${order?.state ? order?.state?.name : ""}, ${
                    order?.state ? order?.state?.country?.name : ""
                  }`}
                </p>
              </div>
              <RoundedButton
                name="Apply for this role"
                type="button"
                onClick={() => showSet(true)}
              />
            </div>
          </div>
          <div className="bg-[white] max-w-[1300px] mx-auto border border-[#BCBCBC] rounded-[18px] py-14 px-5 md:px-10 -mt-20 mb-7">
            <div className="max-w-[974px]">
              {order?.description && (
                <div dangerouslySetInnerHTML={{ __html: order?.description }} />
              )}
            </div>
          </div>
          <div className="container">
            <div className="flex justify-end pr-4">
              <RoundedButton
                name="Apply for this role"
                type="button"
                onClick={() => showSet(true)}
              />
            </div>
          </div>
        </div>
        {slug && (
          <CheckEmail
            open={show}
            order_slug={slug}
            onCancel={() => showSet(false)}
          />
        )}
        <SweetAlert
          visible={message ? true : false}
          button_name="Continue"
          onSubmit={() => navigate("/offers")}
        />
        <Footer />
      </div>
    </AnimatedPage>
  );
};

export default JD;
