import React from "react";

// types and state
import { get_available_total } from "../../../services/api/role";

type ITotalRoleAvailable = {
  id: string;
};

const TotalRoleAvailable: React.FC<ITotalRoleAvailable> = ({ id }) => {
  const [total, totalSet] = React.useState<string>("");
  const onLoad = React.useCallback(async () => {
    if (id) totalSet(await get_available_total(id));
  }, [id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return <p>{total}</p>;
};

export default TotalRoleAvailable;
