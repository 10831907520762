import React from "react";
import "./nav_light.css";

// constants and router
import db from "../../constants/database";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import AnimatedButton from "../../components/animation/forms/AnimatedButton";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full font-abel">
      <div className="flex justify-between items-center container mx-auto py-6 px-3 nav_links">
        <Link to="/">
          <motion.img
            src={db.logo}
            whileHover={{ scale: 1.0 }}
            transition={{ duration: 0.3, bounce: 5 }}
            whileTap={{ scale: 1 }}
            alt="25th blue logo"
            className="md:w-[76.75px] w-[50px]"
          />
        </Link>
        <div className="flex items-center">
          <Link to="/" className="pr-3">
            <motion.span
              whileHover={{ color: "#0B60D6", scale: 1.2 }}
              whileTap={{ color: "#0B60D6" }}
              transition={{ duration: 0.3 }}
            >
              Home
            </motion.span>
          </Link>
          <Link to="/marketplace" className="px-3">
            <motion.span
              whileHover={{ color: "#0B60D6", scale: 1.2 }}
              whileTap={{ color: "#0B60D6" }}
              transition={{ duration: 0.3 }}
            >
              Job Marketplace
            </motion.span>
          </Link>
          <Link to="/contact" className="px-3">
            <motion.span
              whileHover={{ color: "#0B60D6", scale: 1.2 }}
              whileTap={{ color: "#0B60D6" }}
              transition={{ duration: 0.3 }}
            >
              Contact Us
            </motion.span>
          </Link>
          <AnimatedButton
            background="rgb(255, 255, 255)"
            color="rgb(11, 96, 214)"
            title="Hire a Domestic Staff"
            onClick={() => navigate("/hire")}
            type="button"
          />
        </div>
      </div>
      <nav className="responsive_nav_mobile px-3 pt-3 text-[16px]">
        <div>
          <input id="responsive-menu" type="checkbox" />
          <label
            htmlFor="responsive-menu"
            className="flex justify-between items-center"
          >
            <Link to="/">
              <motion.img
                src={db.logo}
                whileHover={{ scale: 1.2 }}
                transition={{ duration: 0.3, bounce: 5 }}
                whileTap={{ scale: 1 }}
                alt="25th blue logo"
                className="md:w-[76.75px] w-[50px]"
              />
            </Link>{" "}
            <span id="menu-icon"></span>
          </label>
          <div id="overlay"></div>
          <ul>
            <li className="mb-5">
              <Link to="/">Home</Link>
            </li>
            <li className="mb-5">
              <Link to="/marketplace">Job Marketplace</Link>
            </li>
            <li className="mb-5">
              {" "}
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link
                to="/hire"
                className="border-[#0B60D6] border-[2px] bg-primary py-3 w-[164px] md:w-[174px] h-[52px] flex justify-center items-center rounded-default text-[13px] md:text-[16px] b_shadow text-[#fff] mx-auto"
              >
                Hire a Domestic Staff
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
