import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setGender } from "../../store/slices/gender";

export const get_genders = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-genders");

    dispatch(setGender(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      console.log(error?.response);
      dispatch(setGender([]));
    }
  }
};
