export const get_week_past = (created_at: Date) => {
  const date = new Date(created_at).getTime();
  const today = new Date().getTime();

  const weeks = Math.floor((today - date) / (1000 * 60 * 60 * 24 * 7));

  if (weeks > 0) return `${weeks} weeks(s) ago`;
  else return get_days_past(created_at);
};

export const get_days_past = (created_at: Date) => {
  const date = new Date(created_at).getTime();
  const today = new Date().getTime();

  const days = Math.floor((today - date) / (1000 * 60 * 60 * 24));
  if (days > 1) return `${days} day(s) ago`;
  return "today";
};
