import { isAxiosError } from "axios";
import { setCommunications } from "../../store/slices/communication";
import axiosInstance from "../../utility/axiosInstance";
import { AppDispatch } from "../../store/store";

export const get_communications = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get("/get-communication");

    dispatch(setCommunications(response.data));
  } catch (error) {
    if (isAxiosError(error)) dispatch(setCommunications([]));
  }
};
