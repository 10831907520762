import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { IRequestOrder } from "../../store/types/order";
import { setLoading, setOrder, setOrders } from "../../store/slices/order";
import { setMessage } from "../../store/slices/message";
import { AppDispatch } from "../../store/store";

export const get_orders = async (
  dispatch: AppDispatch,
  q: string = "",
  t: string[] = []
) => {
  dispatch(setLoading());
  try {
    const search = q ? `?q=${q}` : "";
    const type = t.length && search ? `&t=${t}` : t.length ? `?t=${t}` : "";
    const query = search + type;
    const response = await axiosInstance.get(`/get-orders${query}`);

    dispatch(setOrders(response.data));
  } catch (error) {
    if (isAxiosError(error)) console.log("Logger: logging for api");
  }
  dispatch(setLoading());
};

export const get_order = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-order/${id}`);

    dispatch(setOrder(response.data));
  } catch (error) {
    if (isAxiosError(error)) console.log("Logger: logging for api");
  }
  dispatch(setLoading());
};

export const create_order = async (dispatch: Function, data: IRequestOrder) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/request-order", data);

    dispatch(
      setMessage({
        message: response.data?.message,
        title: "We’ll be in touch",
        type: "success",
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(
        setMessage({
          message: error?.response?.data?.message,
          title: "Sonething Happened.",
          type: "error",
        })
      );
    }
  }
  dispatch(setLoading());
};
