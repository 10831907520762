import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

// Define a type for the slice state
interface CounterState {
  genders: IDataType[];
  loading: boolean;
}

// Define the initial state using that type
const initialState: CounterState = {
  genders: [],
  loading: false,
};

export const gender = createSlice({
  name: "gender",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGender: (state, action: PayloadAction<IDataType[]>) => {
      state.genders = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setGender, setLoading } = gender.actions;

export default gender.reducer;
