import { Link } from "react-router-dom";
import db from "../../constants/database";

const Footer = () => {
  return (
    <footer className="w-full font-abel">
      <div className="bg-primary text-[white] w-full px-3 md:py-[80px] py-14">
        <div className="max-w-[1160px] mx-auto flex flex-wrap justify-between items-center">
          <div className="py-2">
            <h5 className="md:text-xl text-[14px] text-[#fff] underline pb-[10px]">
              Contact
            </h5>
            <div className="mb-[0.3rem]">
              <a href="tel:+2349133779831" className="md:text-xl text-[14px]">
                +234 913 377 9831
              </a>
            </div>
            <div className="mb-[0.3rem]">
              <a href="tel:+7136365773" className="md:text-xl text-[14px]">
                +713 636 5773
              </a>
            </div>
            <div className="mb-[0.3rem]">
              <a
                href="mailto:info@25thmail.com"
                className="md:text-xl text-[14px]"
              >
                info@25thmail.com
              </a>
            </div>
            <div className="mb-[0.3rem]">
              <p className="md:text-xl text-[14px]">
                NGN: 9th Floor, 2, Idowu Taylor Street, Victoria Island, Lagos.
              </p>
            </div>
            <div>
              <p className="md:text-xl text-[14px]">
                USA: 3527 Mount Diablo Blvd, Lafayette, CA 94549, USA.
              </p>
            </div>
          </div>
          <div className="border-[white] border-l-2 md:pl-10 pl-3 py-2">
            <img
              src={db.logoWhite}
              alt="25th blue logo"
              className="md:w-[152px] w-[90px]"
            />
          </div>
        </div>
      </div>
      <div className="w-full text-sm md:text-[16px] flex items-center justify-center px-2 py-3 md:px-4 md:py-5 text-[#272727] text-center">
        &#169; 25th Blue 2025.&nbsp;
        <Link
          to="https://25thandstaffing.com/policy"
          target="_blank"
          className="text-[#243677] cursor-pointer"
        >
          Privacy Policy
        </Link>{" "}
        &nbsp;|&nbsp;{" "}
        <Link
          to="https://25thandstaffing.com/terms"
          target="_blank"
          className="text-[#243677] cursor-pointer"
        >
          Terms and Conditions
        </Link>{" "}
        &nbsp;|&nbsp;{" "}
        <Link
          to="https://25thandstaffing.com/services/terms"
          target="_blank"
          className="text-[#243677] cursor-pointer"
        >
          Rebate T&C
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
