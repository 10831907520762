import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface ILoadingState {
  loading: boolean;
}

// Define the initial state using that type
const initialState: ILoadingState = {
  loading: false,
};

export const loading = createSlice({
  name: "loading",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setLoading } = loading.actions;

export default loading.reducer;
