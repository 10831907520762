import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IGetAllOrderResponse, IOrderGet, IOrderState } from "../types/order";

// Define the initial state using that type
const initialState: IOrderState = {
  orders: [],
  order: null,
  current_page: "1",
  last_page: "1",
  loading: false,
};

export const order = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<IGetAllOrderResponse>) => {
      state.orders = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setOrder: (state, action: PayloadAction<IOrderGet>) => {
      state.order = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setOrders, setOrder, setLoading } = order.actions;

export default order.reducer;
